import type { Client, GlobalFunctions, Role } from './types';

declare global { interface Window extends GlobalFunctions {} }

const validationMessages = {
    requiredEmail: "Please specify an email address.",
    email: "Email format is invalid.",
    phone: "Phone number format is invalid."
};

export function getAllClientInputs(formSelector: string) {
    var inputs = $(formSelector).serializeArray();
    return {
        'emails': inputs.filter(function (input) {
            return input.name.startsWith('email');
        }),
        'phones': inputs.filter(function (input) {
            return input.name.startsWith('phone');
        }),
        'roles': inputs.filter(function (input) {
            return input.name.startsWith('role');
        }) as { name: string, value: Client['role'] }[],
        'firsts': inputs.filter(function (input) {
            return input.name.startsWith('first');
        }),
        'lasts': inputs.filter(function (input) {
            return input.name.startsWith('last');
        })
    }
}
window.getAllClientInputs = getAllClientInputs;

// Iterate over all email inputs to build array of Clients
export function getClientsFromEnabledEmailInputs(formSelector: string): Client[] {
    var inputs = getAllClientInputs(formSelector);
    var clients: Client[] = [];

    inputs.emails.forEach(function (emailInput, index) {
        if (inputs.roles[index]?.value) {
            const $phoneInput = $('[name="' + inputs.phones[index].name + '"]');
            let client: Client = {
                id: (emailInput.name?.match(/\[([0-9]*?)\]/)?.[1]) ?? '',
                email: emailInput.value,
                phone: $phoneInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.NATIONAL),
                role: inputs?.roles[index]?.value || 'buyer', // since theres a slight delay in setting the role, we default if not provided
                first: inputs.firsts[index].value,
                last: inputs.lasts[index].value
            };
            if (client.email) {
                const clientId = client.id || '';
                clients.push({
                    value: client.id,
                    text: buildClientTextLine(client, clientId),
                    email: client.email,
                    phone: client.phone,
                    role: client.role,
                    first: client.first,
                    last: client.last
                });
            }
        }
    });

    return clients;
}
window.getClientsFromEnabledEmailInputs = getClientsFromEnabledEmailInputs;

export function buildClientTextLine(client: Client, id: string): string {
    let role: string = client.role || '';
    let firstRowText: string;
    let secondRowText: string;

    if (client.first || client.last) {
        firstRowText = client.first + ' ' + client.last;
        secondRowText = client.email + ((client.phone) ? ', ' + client.phone : '');
    } else {
        firstRowText = client.email;
        secondRowText = client.phone || '';
    }

    return $('#client-text-line-template').html()
        .replaceAll("'{role}'", role || '')
        .replaceAll("'{firstRowText}'", firstRowText || '')
        .replaceAll("'{secondRowText}'", secondRowText || '')
        .replaceAll("'{id}'", id);
}
window.buildClientTextLine = buildClientTextLine;

export function buildClientSummaryTextLine(client: Client): string {
    var role: string = client.role || '';
    var firstRowText: string;

    if (client.first || client.last) {
        firstRowText = client.first + ' ' + client.last;
    } else {
        firstRowText = client.email;
    }

    return $('#client-summary-text-line-template').html()
        .replaceAll("'{role}'", role || '')
        .replaceAll("'{firstRowText}'", firstRowText || '');
}
window.buildClientSummaryTextLine = buildClientSummaryTextLine; 

export function setClientTextToSummaryTextLine(clients: Client[]): Client[] {
    return $.map(clients, function(client: Client) {
        client.text = buildClientSummaryTextLine(client);
        return client;
    });
}
window.setClientTextToSummaryTextLine = setClientTextToSummaryTextLine;

export function prepareModalForFormClientEditing(contactFields: string[], editedClientId: string) {
    // For each input field, populate the 'edit' input field with the current input data for the client being edited
    // e.g. input:email.2 => input:email.edit
    $.each(contactFields, function(idx: number, contactField: string) {
        var currentContactData = $("input[data-id='" + contactField + "." + editedClientId + "']").val() || '';
        $("input[data-id='" + contactField + ".edit']").val(currentContactData);
    });

    // Handle role differently due to radio buttons
    var currentRole = $("input[data-id='role." + editedClientId + "']:checked").val();
    $("input[data-id='role.edit'][value='" + currentRole + "']").click().blur();
    
    const $editNewClientModal = $('#editNewClientModal');
    $editNewClientModal.modal('show');
}
window.prepareModalForFormClientEditing = prepareModalForFormClientEditing;

export function handleEditedFormClient(contactFields: string[], editedClientId: string) {
    // We will need to update our 'imported' clients with our edited client info.
    // var clients = getAvailableClients('imported');
    var clients = window.getAvailableClients('new');
    var client = clients.find(client => client.value == editedClientId);
    let oldEmail: string | undefined;

    if (client) {
        oldEmail = client['email'];
    }

    // For each 'edit' input field, overwrite the edited client input field with the edited data.
    // e.g. input:email.edit => input:email.2
    // Also update the edited client for resetting our 'imported' available clients. This allows us to
    // rebuild the select clients selector with the updated/edited client
    $.each(contactFields, function(idx: number, contactField: string) {
        var editedContactData = $("input[data-id='" + contactField + ".edit']").val() || '';
        $("input[data-id='" + contactField + "." + editedClientId + "']").val(editedContactData);
        if (client) {
            // @TODO this is a hack, but we're not really validating what's being edited here. 
            (client as any)[contactField] = editedContactData;
        }
    });

    // Handle role differently than above contact fields due to radio buttons
    var newRole = $("input[data-id='role.edit']:checked").val();
    $("input[data-id='role." + editedClientId + "'][value='" + newRole + "']").click().blur();

    if (client) {
        client['role'] = newRole as Role;
        // Rebuild the text that will show in the select clients selector for the edited client
        client['text'] = buildClientTextLine(client, editedClientId);
    }

    // Update our available 'imported' clients with the edited client
    window.setAvailableClients('new', clients);

    // Update the drop downs with the edited client
    window.updateRecipientsLists();

    // If email changes, we must update saved edited eSign documents
    if (client && oldEmail !== client['email']) {
        window.handleEmailChangeForSigners(editedClientId);
    }

    toastr.success('Contact information updated');
    const $editNewClientModal = $('#editNewClientModal');
    $editNewClientModal.modal('hide');
}
window.handleEditedFormClient = handleEditedFormClient;

export function enableClientInputs(clientElement: JQuery<HTMLElement>) {
    toggleDisabledOnClientInputs(clientElement, false);
}
window.enableClientInputs = enableClientInputs;

export function disableClientInputs(clientElement: JQuery<HTMLElement>) {
    toggleDisabledOnClientInputs(clientElement, true);
}
window.disableClientInputs = disableClientInputs;

export function toggleDisabledOnClientInputs(clientElement: JQuery<HTMLElement>, disabled: boolean) {
    var firstInput = clientElement.find('.client-first');
    var lastInput = clientElement.find('.client-last');
    var emailInput = clientElement.find('.client-email');
    var phoneInput = clientElement.find('.client-phone');
    var roleInput = clientElement.find('.client-role');  // toggle all role inputs (1 for each role)

    firstInput.prop('disabled', disabled);
    lastInput.prop('disabled', disabled);
    emailInput.prop('disabled', disabled);
    phoneInput.prop('disabled', disabled);
    roleInput.prop('disabled', disabled);
}
window.toggleDisabledOnClientInputs = toggleDisabledOnClientInputs;

export function clearMainFormClients(formSelector: JQuery.Selector = '#addClientForm') {
    clearClients(formSelector);
    $('#addClientButton').trigger('click');
    window.updateRecipientsLists(formSelector);
}
window.clearMainFormClients = clearMainFormClients;

export function addClientButtonListener(addClientNow?: boolean) {
    const addClientBtn = document.getElementById('addClientButton');
    addClientBtn?.addEventListener('click', function () {
        addClient(this, true);
    });

    // Init client input 0. Must be done after '$("#addClientForm").validate();'
    if (addClientNow) {
        addClientBtn?.click();
    }
}

export function addClient(that: HTMLElement, addOnChangeEvent: boolean) {
    addClientWithRole(that, addOnChangeEvent);
}

export function addClientWithRole(that: HTMLElement, addOnChangeEvent: boolean, role?: Role): JQuery<HTMLElement> {
    var $form = $(that).parents('form');
    var $lastClientEmail = $form.find('.client_contact_container .client-email[data-counter]').last();
    var counter = parseInt($lastClientEmail.attr('data-counter') || '-1') + 1;

    var newClientHtml = $('#client-contact-template').html()
        .replaceAll('{counter}', counter.toString());

    var newClientElement = $(newClientHtml).appendTo($form.find('.clients-container'));
    if (role) {
        // 'role' must be selected here so that the updates to the recipients list already have 'role' set for the
        // default selections (e.g. wireDocRecipients are auto-selected for the roles 'buyer' and 'lender'
        var roleInput = newClientElement.find('.client-role[value=' + role.toLowerCase() + ']');
        roleInput.trigger('click').trigger('blur');
    }

    var clientInputElements = newClientElement.find('.client-first, .client-last, .client-email, .client-phone, .client-role');
    clientInputElements.keyup(function () {
        window.clearFormControlErrorMessageHandler(this, '.client-contact-input');
    });

    if (addOnChangeEvent) {
        clientInputElements.on('change', function () {
            window.updateRecipientsLists('#' + $form[0].id);

            // If email changes, we must update the edited eSign documents
            if ($(this).hasClass('client-email')) {
                window.handleEmailChangeForSigners($(this).data('counter'));
            }
        });
    }

    setEmailValidation(newClientElement.find('.client-email'));
    setPhoneValidation(newClientElement.find('.client-phone'));

    return newClientElement;
}
window.addClientWithRole = addClientWithRole;

export function removeClient(that: HTMLElement) {
    var formId = '#' + $(that).parents('form')[0].id;
    $(that).parents('.client_contact_container').remove();
    window.updateRecipientsLists(formId);
}
window.removeClient = removeClient;

export function clearClients(formID: string) {
    $(formID).find('.client_contact_container').remove();
}
window.clearClients = clearClients;

export function setEmailValidation($inputElement: JQuery) {
    $inputElement.rules("add", {
        required: true,
        email: true,
        messages: {
            required: validationMessages.requiredEmail,
            email: validationMessages.email 
        }
    });
}
window.setEmailValidation = setEmailValidation;

export function setPhoneValidation($inputElement: JQuery) {
    if ($inputElement && $inputElement.length) {
        $inputElement
            .on('keyup change', function (event) {
                resetTelInputErrors(event.target);
            })
            .on('blur', function (event) {
                validateTelInput(event.target);
            })
            .intlTelInput();
    }
}
window.setPhoneValidation = setPhoneValidation;

export function validateTelInput(element: HTMLElement) {
    var $element = $(element);
    var $elementErrorLabel = $element.parent().next();
    const $elVal = $.trim($element.val()?.toString() ?? '');

    if ($elVal && !$element.intlTelInput('isValidNumber')) {
        $element.addClass("error");
        $elementErrorLabel.text(validationMessages.phone);
    }
}
window.validateTelInput = validateTelInput;

export function resetTelInputErrors(element: HTMLElement) {
    var $element = $(element);
    var $elementErrorLabel = $element.parent().next();
    $element.removeClass("error");
    $elementErrorLabel.text('');
}
window.resetTelInputErrors = resetTelInputErrors;
