export {
    addClient, addClientButtonListener, addClientWithRole,
    buildClientSummaryTextLine, buildClientTextLine, clearClients, clearMainFormClients, disableClientInputs, enableClientInputs, getAllClientInputs,
    getClientsFromEnabledEmailInputs, handleEditedFormClient, prepareModalForFormClientEditing, removeClient, resetTelInputErrors, setClientTextToSummaryTextLine, setEmailValidation,
    setPhoneValidation, toggleDisabledOnClientInputs, validateTelInput
} from './form-clients';

export { validateForm } from './form-formatting-and-validation';

export { initClearFormErrors } from './clear-form-errors';

// If ScriptRegistry is available, register this script
window.ScriptRegistry?.register(window.ScriptRegistry.knownScripts.addFile);
