declare global {
    interface Window {
        clearFormControlErrorMessageHandler: typeof clearFormControlErrorMessageHandler
    }
}

// Clear validation messages on input
export function clearFormControlErrorMessageHandler(
    element: HTMLElement | JQuery.Selector,
    parentElement: JQuery.Selector = '.form-group'
) {
    $(element as JQuery.Selector)
        .removeClass('error')
        .parents(parentElement)
        .first()
        .find('.form-control-error-description')
        .text('')
        .removeClass('error');
}
window.clearFormControlErrorMessageHandler = clearFormControlErrorMessageHandler;

export const initClearFormErrors = (formId: JQuery.Selector) => {
    $(`${formId} input[type="text"], ${formId} input[type="tel"]`).on('keyup', function () {
        clearFormControlErrorMessageHandler(this);
    });
    $(`${formId} input[type="radio"], ${formId} select`).on('change', function () {
        clearFormControlErrorMessageHandler(this);
    });
}
